import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import { Conditional } from '../../core/Conditional';
import './index.scss';

const TYPE = {
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary'
};

const HeaderType = ({type, title}) => {
  switch (type) {
    case TYPE.PRIMARY:
      return (<h1>{title || '--'}</h1>);
    case TYPE.SECONDARY:
      return (<h2>{title || '--'}</h2>);
    default:
  }
  return (<h1>{title || '--'}</h1>);
};

HeaderType.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string
};

function PageHeader ({title, description, type}) {
  return (
    <div className="page-header">
      <div className="page-header__head">
        <div className="page-header__title">
          <HeaderType title={title} type={type} />
          <Conditional condition={description}>
            <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(description || '--')}} />
          </Conditional>
        </div>
      </div>
    </div>
  );
}

PageHeader.defaultProps = {
  type: 'Primary'
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  type: PropTypes.string
};

export default PageHeader;
