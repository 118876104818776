import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PageHeader from '../../../../../components/app/PageHeader';
import Snackbar from '../../../../../components/core/Snackbar/index';
import SnackbarList from './SnackbarList';
import { labelSelector } from '../../../../../selectors/app';
import { autocompleteSnackbarList } from '../../selectors';
import Constants from '../../../../../constants/appConstants';
import './index.scss';

const UserSearch = ({ snackbarItems = [], labels }) => (
  <div className='portfolio__header'>
    <SnackbarList />
    <TransitionGroup component={null}>
      {snackbarItems.map((data, idx) => (
        <CSSTransition key={idx} classNames='snackbar__transition' timeout={Constants.CSS_DURATION_HORSE}>
          <Snackbar labels={labels} {...data} />
        </CSSTransition>
      ))}
    </TransitionGroup>
    <div className='header-container'>
      <div className='header-container__left'>
        {PageHeader({
          title: labels.tkAdministratorDashboard,
          description: labels.tkAdministratorDashboardDescription || '--',
        })}
      </div>
    </div>
  </div>
);

UserSearch.propTypes = {
  labels: PropTypes.object,
  snackbarItems: PropTypes.array,
  autocomplete: PropTypes.array,
};

const mapStateToProps = state => ({
  labels: labelSelector(state),
  snackbarItems: autocompleteSnackbarList(state),
});

export default connect(mapStateToProps)(UserSearch);
