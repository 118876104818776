import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import translator from '../../../../services/translator';
import Button from '../../../../components/core/Button';
import {Conditional} from '../../../../components/core/Conditional';
import {toggleBrowserScrollVisiblilty} from '../../../../utils/dom';
import {
  filterConfigSelector, filterDataSelector,
  filterDataCountSelector, getEntitledFirmBranchAccountFundCount
} from "../../../../selectors/pages/reports/individualReport";
import FilterModal from './FilterModal';

const {translate: t} = translator;

const containsColumns = (columns = []) => {
  const columnsMap = {
    firmBranch: false,
    account: false,
    shareclass: false
  };
  columns.forEach((column) => {
    const {key, include} = column;
    columnsMap[key] = { include, contains: true };
  });
  return columnsMap;
};

const DataFilterButton = (props) => {
  const {
    filterConfig = {},
    initialEntitlementCount,
    selectedFilterDataCount,
    selectedFilterData
  } = props;
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const columnsMap = useCallback(() => {
    return containsColumns(filterConfig.columns);
  }, [filterConfig])
  const getSelectedFilterDetails = useCallback(() => {
    const {firmBranch, account, shareclass} = columnsMap();
    if (isEmpty(selectedFilterDataCount)) {
      let str = '';
      if (firmBranch.contains) str += `${t("tkAllFirms").toUpperCase()}, ${t("tkAllBranches").toUpperCase()}, `;
      if (account.contains) str += `${t("tkAllAccounts").toUpperCase()}, `
      if (shareclass.contains) str += `${t("tkAllFunds").toUpperCase()},`
      return str.trim().substring(0, str.length - 1);
    }
    const {
      firmsCount,
      branchesCount,
      accountsCount,
      shareclassesCount,
      shareclassIdsWithoutAcctCount
    } = selectedFilterDataCount;
    const {
      firmsCount: totalFirmsCount,
      branchesCount: totalBranchesCount,
      accountsCount: totalAccountsCount,
      shareclassesCount: totalShareclassCount,
      shareclassWithoutAcctCount: totalShareclassWithoutAcctCount
    } = initialEntitlementCount;
    let str = '';
    if (firmBranch.contains) {
      if (firmsCount === totalFirmsCount) {
        str += `${t("tkAllFirms").toUpperCase()}, `;
      } else {
        str += `${firmsCount} ${t('tkFirms').toUpperCase()}, `;
      }
      if (branchesCount === totalBranchesCount) {
        str += `${t("tkAllBranches").toUpperCase()}, `;
      } else {
        str += `${branchesCount} ${t('tkBranches').toUpperCase()}, ` ;
      }
    }
    if (account.contains) {
      if (accountsCount === totalAccountsCount) {
        str += `${t("tkAllAccounts").toUpperCase()}, `;
      } else {
        str += `${accountsCount} ${t('tkAccounts').toUpperCase()}, `;
      }
    }
    if (shareclass.contains) {
      const allShareclassCounts = shareclass.include && shareclass.include === "unfunded"
        ? totalShareclassCount + totalShareclassWithoutAcctCount
        : totalShareclassCount;
      const allShareclassesCount = shareclass.include && shareclass.include === "unfunded"
        ? shareclassesCount + shareclassIdsWithoutAcctCount
        : shareclassesCount;
      if (allShareclassesCount === allShareclassCounts) {
        str += `${t("tkAllFunds").toUpperCase()}, `;
      } else {
        str += `${allShareclassesCount} ${t('tkFunds').toUpperCase()}, `;
      }
    }
    return str.trim().substring(0, str.length - 2);
  }, [selectedFilterDataCount]);

  const openFilterModal = useCallback(() => {
    setShowFiltersModal(true);
  }, []);

  const onCloseClick = useCallback(() => {
    toggleBrowserScrollVisiblilty(false);
    setShowFiltersModal(false);
  }, []);

  return (
    <div>
      <Conditional condition={filterConfig}>
        <div className="action c-align">
          <span>{t('tkViewColon')}</span>
          <span className='filter-details'>{getSelectedFilterDetails()}</span>
          <Button
            iconClass="filter-icon"
            clickHandler={openFilterModal}
          />
          {showFiltersModal &&
          <FilterModal
            handleClose={onCloseClick}
            setFilterData={selectedFilterData}
            showModal={showFiltersModal}
          />}
        </div>
      </Conditional>
    </div>

  )
};

DataFilterButton.propTypes = {
  filterConfig: PropTypes.object,
  initialEntitlementCount: PropTypes.object,
  selectedFilterData: PropTypes.object,
  selectedFilterDataCount: PropTypes.object
};

const mapStateToProps = (state) => ({
  filterConfig: filterConfigSelector(state),
  initialEntitlementCount: getEntitledFirmBranchAccountFundCount(state),
  selectedFilterData: filterDataSelector(state),
  selectedFilterDataCount: filterDataCountSelector(state)
});

export default connect(mapStateToProps)(DataFilterButton);
