import React, { useCallback } from 'react';
import useUserMaintenance from '../../../context';
import ProfileGroup from './ProfileGroup';
import { profileConfig } from './config';
import { UPDATE_PROFILE_SSO_FIELDS, VALIDATE_INPUT, UPDATE_PAGE_EDITED } from '../../../actions';
import translator from '../../../../../../services/translator';
import FieldRenderer from '../../FieldRenderer';
import './index.scss';

const { translate: t } = translator;

const Profile = () => {
  const {
    state: { isEditable, userDetails: { ssoClient = [] } = {}, isInputValid, isPageEdited },
    storeValues: { isInternalUser },
    dispatch,
  } = useUserMaintenance();

  const {
    thirdPartyPartnerSsoEntitlementsConfig: [clearwater, ionTreasury, gTreasury],
    identifyingInformationConfig,
    contactInformationConfig,
    setupConfig,
    functionalityConfig,
    accessConfig,
    changeManagementDetails,
  } = profileConfig;

  const getSSOValue = value =>
    ssoClient.find(({ clientName }) => clientName === value) || {
      clientName: value,
      alias: '',
      isEnabled: false,
    };

  const handleChange = useCallback(
    ({ event: { type } = {}, value, config, errorMessage }) => {
      if (type === 'change') {
        if (!isPageEdited) {
          dispatch({ type: UPDATE_PAGE_EDITED, payload: { isPageEdited: true } });
        }
      } else {
        dispatch({ type: UPDATE_PROFILE_SSO_FIELDS, payload: { newValue: value, config } });
        if (config.validation) {
          dispatch({ type: VALIDATE_INPUT, payload: { fieldName: config.value, isValid: !errorMessage, errorMessage } });
        }
      }
    },
    [isPageEdited]
  );

  return (
    <React.Fragment>
      <div className='main-header'>{t('tkUserProfile')}</div>
      <div className='profile-main-container'>
        <div className='profile-main-container__left'>
          <ProfileGroup data={identifyingInformationConfig} groupTitle='tkIdentifyingInformation' testId='identifying-information' />
          <ProfileGroup data={contactInformationConfig} groupTitle='tkContactInformation' testId='contact-information' />
          <div className='profile-main-container__info-section-container'>
            <div data-testid='SSO-entitlements' className='profile-main-container__header-title'>
              {t('tkThirdPartyPartnerSsoEntitlements')}
            </div>
            <FieldRenderer
                isEditable={isEditable}
                config={clearwater}
                handleChange={handleChange}
                value={getSSOValue(clearwater.value)}
                sectionStart={true}
                errorState={isInputValid[clearwater.value]}
              />
              <FieldRenderer
                isEditable={isEditable}
                config={ionTreasury}
                handleChange={handleChange}
                value={getSSOValue(ionTreasury.value)}
                errorState={isInputValid[ionTreasury.value]}
              />
              <FieldRenderer
                isEditable={isEditable}
                config={gTreasury}
                handleChange={handleChange}
                value={getSSOValue(gTreasury.value)}
                sectionEnd={true}
                errorState={isInputValid[gTreasury.value]}
              />
          </div>
        </div>
        <div className='profile-main-container__right'>
          <ProfileGroup data={setupConfig} groupTitle='tkSetup' testId='setup' />
          {isInternalUser && <ProfileGroup data={functionalityConfig} groupTitle='tkFunctionality' testId='functionality' />}
          <ProfileGroup data={accessConfig} groupTitle='tkAccess' testId='access' />
          <ProfileGroup data={changeManagementDetails} groupTitle='tkChangeManagementDetails' testId='change-management-details' />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Profile;
