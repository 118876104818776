import { createSelector } from 'reselect/lib/index';
import Constants, { PAGE_NAV_ITEM } from '../../constants/appConstants';
import { TRADDING_NOT_ALLOWED_PAGEID } from '../../constants/tradeBlotter';
import { pageIdSelector } from '../pages/index';

export const userSelector = state => state.user;
export const userIdSelector = state => state.user && state.user.id ? state.user.id : '';
export const firstNameSelector = state => state.user && state.user.firstName ? state.user.firstName : '';
export const lastNameSelector = state => state.user && state.user.lastName ? state.user.lastName : '';
export const permissionSelector = state => state.user && state.user.permissions ? state.user.permissions : {};
export const jurisdictionSelector = state => state.user && state.user.jurisdiction || '';
export const userGuidSelector = state => state.user && state.user.userGuid ? state.user.userGuid : '';
export const isInternalUserSelector = state => state.user && state.user.isInternal;
export const homeAwaySelector = state => state.user && state.user.permissions && state.user.permissions.homeAway;
export const isTradingAllowedSelector = createSelector(permissionSelector, userSelector, pageIdSelector,
  (permissions, user, pageId) => {
    const hasPermission = !!(user && permissions && permissions.canTrade && permissions.allowFeatures && permissions.allowFeatures.indexOf(Constants.TRADING_FEATURE_ENTITLEMENT) > -1);
    const allowedRoute = !TRADDING_NOT_ALLOWED_PAGEID.includes(pageId);
    return hasPermission && allowedRoute;
  });

// Due to circular dependency we have duplicated here
export const isMimicSessionSelector = state => state.appContext && state.appContext.mimicInfo && state.appContext.mimicInfo.isMimicSession || false;

export const allowFeatures = state => state.user && state.user.permissions && state.user.permissions.allowFeatures ? state.user.permissions.allowFeatures : [];
export const allowFeaturesSelector = createSelector(
  allowFeatures,
  isMimicSessionSelector,
  (allowFeatures, isMimicSession) => {
    return isMimicSession ? allowFeatures.filter((allowFeature) => allowFeature !== 'SELFSERVICEADMIN') : allowFeatures
  }
);
export const allowShellAccountsSelector = state => state.user && state.user.permissions && state.user.permissions.allowShellAccounts ? state.user.permissions.allowShellAccounts : false;
export const errorSelector = state => state.user && state.user.error || null;
export const getUserTimeZone = state => state.user && state.user.userTimeZone.timeZone;
export const getUserTimeZoneId = state => state.user && state.user.userTimeZone.zoneId;

export const canShowEmailPreferenceNavItemSelector = createSelector(
  allowFeaturesSelector,
  (allowFeatures) => {
    return allowFeatures.includes(PAGE_NAV_ITEM.TRADING) || allowFeatures.includes(PAGE_NAV_ITEM.TRADEAPPROVAL);
  }
);

export const isImportTradesAllowedSelector = createSelector(allowFeaturesSelector, isTradingAllowedSelector, (allowFeatures, isTradingAllowed) => {
  return allowFeatures.includes(PAGE_NAV_ITEM.TRADEIMPORT) && isTradingAllowed;
});

export const isInvestmentPolicyAllowedSelector = createSelector(allowFeaturesSelector, (allowFeatures) => {
  return allowFeatures.includes(PAGE_NAV_ITEM.INVESTMENTPOLICY);
});

export const isClearWaterSSOEnabledSelector = createSelector(userSelector, (user = {}) => {
  return user.userSSOInfos && user.userSSOInfos.some(userSSOInfo => userSSOInfo.ssoClientId === 1 && userSSOInfo.isSSOEnabled)
});

export default {
  userIdSelector,
  firstNameSelector,
  lastNameSelector,
  permissionSelector
};
