import React, { useCallback, useState } from "react";
import PropTypes from 'prop-types';
import CheckboxTree from "react-checkbox-tree";
import CheckBox from "../../../../../../components/core/CheckBox";
import "../../../../../../components/core/CheckboxTree/index.scss";

const icons = {
  check: <span className="rct-icon rct-icon-check" />,
  uncheck: <span className="rct-icon rct-icon-uncheck" />,
  halfCheck: <span className="rct-icon rct-icon-half-check" />,
  expandClose: <span className="rct-icon rct-icon-expand-close" />,
  expandOpen: <span className="rct-icon rct-icon-expand-open" />,
  expandAll: <span className="rct-icon rct-icon-expand-all" />,
  collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
  parentClose: <span className="rct-icon rct-icon-parent-close" />,
  parentOpen: <span className="rct-icon rct-icon-parent-open" />,
  leaf: <span className="rct-icon rct-icon-leaf" />,
};

const getActionObj = ({checked, targetNode}) => {
  let updatedSelectedValues = checked;
  const {isParent} = targetNode;
  const type = isParent ? "FIRM_CHANGE" : "BRANCH_CHANGE";
  if(isParent){
    updatedSelectedValues = checked.includes(targetNode.value) ? [...checked] : [targetNode.value, ...checked];
  } else{
    const parentId = targetNode.parent.id;
    if(!targetNode.checked){
      if(checked.includes(parentId)){
        if(!targetNode.parent.children.map((child)=> child.id).some(branch=>checked.includes(branch))){
          updatedSelectedValues = checked.filter((id) => id!==parentId);
        }
      }
    } else {
      updatedSelectedValues = checked.includes(parentId) ? [...checked] : [parentId, ...checked]
    }
  }
  return {type, data: { targetNode, selected: updatedSelectedValues }};
}

const getExpandedIds = (entityMap = []) => {
  const expandedIds = []
  entityMap.forEach((node) => {
    const { children = [], value } = node;
    expandedIds.push(value);
    children.forEach(({ value: branchId }) => {
      expandedIds.push(branchId);
    })
  })
  return expandedIds;
}

const FirmBranch = React.memo((props) => {
  const { entityMap, handleChange, selected, filterText, totalSelection } = props;
  const [expanded, setExpanded] = useState(() => getExpandedIds(entityMap));

  const handleAllClick = useCallback((_value, checked) => {
    handleChange({type: "FIRM_BRANCH_ALL_CHANGED", data: { selected: checked ? [...entityMap] : [] }});
  }, []);

  const handleNodeCheck = useCallback((checked, targetNode) => {
    const action = getActionObj({checked, targetNode})
    handleChange(action);
  }, []);

  const onExpand = useCallback((expanded) => {
    setExpanded(expanded);
  }, []);

  const showAllCheckbox = () => {
    return (filterText === "" || !filterText)
  }

  return (
    <div>
      {showAllCheckbox() &&
        <div className="rct-list__check-all">
          <CheckBox
            changeHandler={handleAllClick}
            isChecked={
              selected.length === totalSelection
            }
            value="all"
            label="All"
            id="firmBranch-all"
          />
        </div>
      }
      <div className="rct-list__content">
        <CheckboxTree
          icons={icons}
          checked={selected}
          expanded={expanded}
          showNodeIcon={false}
          id={`tree-firmBranch`}
          nodes={entityMap}
          onCheck={handleNodeCheck}
          onExpand={onExpand}
        />
      </div>
    </div>
  )
});

FirmBranch.propTypes = {
  entityMap: PropTypes.array,
  handleChange: PropTypes.func,
  selected: PropTypes.array,
  filterText: PropTypes.string,
  totalSelection: PropTypes.number
}

export default FirmBranch;
