/* eslint-disable no-useless-escape */
import translator from '../../../../../services/translator';

const { translate: t } = translator;

export const validateLength = (text, minChar, maxChar) => {
  if (text.length < minChar) {
    return {
      isValid: false,
      errorMessage: t('tkIdentifierCannotBeEmpty'),
    };
  }
  if (text.length > maxChar) {
    return {
      isValid: false,
      errorMessage: t('tkMaxCharacterCountReached'),
    };
  }
  return {
    isValid: true,
    errorMessage: null,
  };
};

export const validateEmail = email => {
  const emailRegex = /\S+@\S+\.\S+/;
  return emailRegex.test(email) ? { isValid: true, errorMessage: null } : { isValid: false, errorMessage: t('tkInvalidEmailAddress') };
};

export const validatePhone = phoneNumber => {
  const phoneRegex = /^\d{10,20}$/;
  const containsLettersRegex = /[a-zA-Z]/;
  const containsSpecialCharactersRegex = /[!@#\$%\^\&*\)\(+=._-]/;
  if (containsLettersRegex.test(phoneNumber) || containsSpecialCharactersRegex.test(phoneNumber)) {
    return {
      isValid: false,
      errorMessage: t('tkOnlyNumericValuesAllowed'),
    };
  }
  return phoneRegex.test(phoneNumber)
    ? { isValid: true, errorMessage: null }
    : { isValid: false, errorMessage: phoneNumber.length > 20 ? t('tkMaxCharacterCountReached') : t('tkMinCharacterCountRequired') };
};
