import React, { useState, useEffect, useRef } from 'react';
import { Input } from '@gs-ux-uitoolkit-react/input';
import { Switch } from '@gs-ux-uitoolkit-react/switch';
import throttle from 'lodash/throttle';
import cn from 'classnames';
import PropTypes from 'prop-types';
import translator from '../../../../../services/translator';

const { translate: t } = translator;

const InputWithSwitch = props => {
  const ref = useRef();
  const { config, value, errorState, handleChange } = props;
  const { isEnabled, alias } = value || {};
  const { validation } = config;

  const [tsInput, setTsInput] = useState(alias);
  const [tsSwitchStatus, setTsSwitchStatus] = useState(!!isEnabled);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (errorState) {
      setErrorMessage(errorState.errorMessage);
    }
  }, [errorState]);

  const updateError = input => {
    let error = null;
    if (validation) {
      const { errorMsg } = validation(input, config);
      error = errorMsg;
    }
    setErrorMessage(error);
    return error;
  };

  const handleUpdate = (event, isEnabled, alias) => {
    const errorMessage = isEnabled ? updateError(alias) : '';
    handleChange({
      event,
      value: { ...value, isEnabled, alias },
      config,
      errorMessage,
    });
  };

  const onTsSwitchToggle = event => {
    const switchStatus = event.target.checked;
    setTsSwitchStatus(switchStatus);
    if (!switchStatus) {
      setErrorMessage('');
    }
    const updatedValue = { ...value, isEnabled: switchStatus };
    handleChange({
      event,
      value: updatedValue,
      config,
      errorMessage: switchStatus ? errorMessage : '',
    });
  };

  const onBlur = event => {
    if (tsInput !== alias) {
      handleUpdate(event, tsSwitchStatus, tsInput);
    }
  };

  const onDocumentClick = event => {
    if (!errorMessage && !tsInput && tsSwitchStatus && ref && ref.current && !ref.current.contains(event.target)) {
      handleUpdate(event, tsSwitchStatus, tsInput);
    }
  };

  useEffect(() => {
    const throttleDocumentClick = throttle(onDocumentClick, 100);
    document.addEventListener('click', throttleDocumentClick);
    // cleans up event listeners by removing them when the component is unmounted
    return () => {
      document.removeEventListener('click', throttleDocumentClick);
    };
  }, [tsInput, tsSwitchStatus, errorMessage]);

  const onTsInputChange = event => {
    const userInput = event.target.value;
    setTsInput(userInput);
    handleUpdate(event, tsSwitchStatus, userInput);
  };

  return (
    <div className='textswitch-container'>
      <div className='input-edit' ref={ref}>
        <Input
          size='md'
          className={cn(
            'textswitch-container__text',
            { 'textswitch-container__default': !errorMessage },
            { 'textswitch-container__error': errorMessage }
          )}
          value={tsInput}
          placeholder={t('tkIdentifier')}
          onChange={onTsInputChange}
          onBlur={onBlur}
          disabled={!tsSwitchStatus}
          status={errorMessage ? 'error' : 'none'}
        />
        {errorMessage && <span className='error-message'>{errorMessage}</span>}
      </div>
      <Switch toggledOn={tsSwitchStatus} onChange={onTsSwitchToggle} size='sm' className='textswitch-container__switch' />
    </div>
  );
};

InputWithSwitch.propTypes = {
  value: PropTypes.object,
  config: PropTypes.object,
  errorState: PropTypes.object,
  handleChange: PropTypes.func,
};

export default InputWithSwitch;
