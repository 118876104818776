import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import TabSwitchBar, { TABS } from './TabSwitchBar';
import HelpGuideProvider from '../../../../../components/core/HelpGuideTray/HelpGuideProvider';
import useUserMaintenance from '../../context';
import useSnackbar from '.././../hooks/useSnackbar';
import { decideHelpGuideArray } from '../../utils/helpGuide';
import { helpGuideConfig } from '../../constants/configs/helpGuideConfig';
import { addPageContext } from '../../../../../actions/page';
import { dispatchAnalytics, userDetailsLoad } from '../../analytics';
import { ADD_UPDATE_USER_DETAILS, SHOW_LOADER, TAB_SWITCHED } from '../../actions';

const UserDetails = ({ userDetails }) => {
  const {
    state: { activeTab, showLoader },
    storeValues: { isInternalUser },
    dispatch,
    reduxDispatch,
  } = useUserMaintenance();

  const { closeSnackbar } = useSnackbar();

  useEffect(() => {
    activeTab !== TABS.PROFILE && dispatch({ type: TAB_SWITCHED, payload: TABS.PROFILE });
  }, []);

  useEffect(() => {
    dispatch({
      type: ADD_UPDATE_USER_DETAILS,
      payload: { userDetails },
    });
    showLoader && userDetails && dispatch({ type: SHOW_LOADER, payload: false });
    if (userDetails) {
      dispatchAnalytics(reduxDispatch, userDetailsLoad());
    }
  }, [userDetails]);

  useEffect(() => {
    return () => {
      closeSnackbar('noOrgSnackbar');
      if (userDetails) {
        reduxDispatch(addPageContext({ userDetails: null }));
      }
    };
  }, []);

  const memoizedHelpGuideConfig = useMemo(() => decideHelpGuideArray(helpGuideConfig.userDetails, activeTab, isInternalUser), [activeTab]);

  return (
    <HelpGuideProvider helpGuideConfig={memoizedHelpGuideConfig}>
      <Header />
      <div className='usersearch-details-container'>
        <TabSwitchBar />
      </div>
    </HelpGuideProvider>
  );
};

UserDetails.propTypes = {
  userDetails: PropTypes.object,
};

export default UserDetails;
