import { useState, useCallback, useEffect, useRef } from 'react';
import fetchService from '../../../../../services/fetchService';

const useFetch = (endPointsMapper, onSuccess, onError) => {
  const ref = useRef();
  const [state, setState] = useState({
    response: null,
    error: null,
    loading: false,
    success: null,
  });

  const { loading, response, success, error } = state;

  const doFetch = useCallback(
    async payLoad => {
      ref.current = true;
      setState({ ...state, loading: true, success: null, error: null });
      try {
        const response = await fetchService(
          endPointsMapper.path,
          JSON.stringify(payLoad),
          endPointsMapper.method,
          endPointsMapper.type || 'application/json'
        );
        if (ref.current) {
          setState({ response, loading: false, success: true, error: false });
        }
        onSuccess && onSuccess(response, payLoad);
        return response;
      } catch (error) {
        if (ref.current) {
          setState({ ...state, loading: false, success: false, error });
        }
        onError && onError(error, payLoad);
        return error;
      }
    },
    [endPointsMapper, onSuccess, onError, ref]
  );

  useEffect(() => {
    return () => {
      ref.current = false;
    };
  }, []);

  return { doFetch, loading, data: response, success, error };
};

export default useFetch;
