export const USERS = 'users';
export const FIRMS = 'firms';
export const SNACKBAR_AUTO_DISMISS = 5000;
export const ORGANIZATIONS = 'organizations';
export const TEXT = 'text';
export const SWITCH = 'switch';
export const DROPDOWN = 'dropdown';
export const TEXT_WITH_SWITCH = 'textWithSwitch';
export const AUTOCOMPLETE_ORG_SEARCH = 'autoCompleteOrgSearch';
export const WORK_EMAIL_ADDRESS = 'WORK_EMAIL_ADDRESS';
export const MOSAIC_INTERNAL = 'MOSAIC_INTERNAL';
export const WORK_PHONE = 'WORK_PHONE';
export const CLEARWATER_ID = 'CLEARWATER_ID';
export const ION_TREASURY_ID = 'ION_TREASURY_ID';
export const G_TREASURY_ID = 'G_TREASURY_ID';
export const SSO_ONLY = 'SSO_ONLY';
export const END_CLIENT_BUFFER = 'END_CLIENT_BUFFER';
export const ALLOW_SHELL_ACCOUNTS = 'ALLOW_SHELL_ACCOUNTS';
export const ALLOW_CLOSEOUTS = 'ALLOW_CLOSEOUTS';
export const YES = 'Yes';
export const NO = 'No';
export const NA = 'NA';
export const BOOLEAN = 'BOOLEAN';
export const MODAL = {
  deactivationConfirmation: 'deactivationConfirmation',
  unsavedChanges: 'unsavedChanges',
  allAccountsConfirmation: 'allAccountsConfirmation',
};
export const sso = {
  Clearwater: 'Clearwater',
  ION: 'ION',
  GSTreasury: 'GSTreasury',
};
export const ACTIVE = 'Active';
export const INACTIVE = 'Inactive';
export const FEATURES = 'Features';
export const ENTITLED_ACCOUNTS = 'entitled';
export const AVAILABLE_ACCOUNTS = 'available';
export const MAINTENANCEALERTS = 'MaintenanceAlerts';
export const UNMAPPED_FIRMS = 'Unmapped Firms';
export const VIEW = 'View';
export const TRADE = 'Trade';
export const APPROVE = 'Approve';
export const CHANGE = 'change';
export const REMOVE = 'remove';
export const CLEARING = 'CLEARING';
export const INTERMEDIARY = 'INTERMEDIARY';
export const SUBSIDIARY = 'SUBSIDIARY';
