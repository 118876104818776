import { v4 as uuidv4 } from 'uuid'
import translator from '../../../../services/translator';

const { translate: t } = translator;

// Returns Node Label
export const renderNodeLabel = level => {
  const labels = {
    0: `(${t('tkFirm')})`,
    1: `(${t('tkBranch')})`,
    2: `(${t('tkAccountName')})`,
    3: `(${t('tkTAAccount')} / ${t('tkAccountID')})`,
  };
  return labels[level] || null;
};

// Returns Node Value
export const getNodeValue = node => {
  if (node.firmName && node.firmCode) return `${node.firmName} - ${node.firmCode}`;
  if (!node.firmName && node.firmCode) return node.firmCode;
  if (node.branchName && node.branchCode) return `${node.branchName} - ${node.branchCode}`;
  if (!node.branchName && node.branchCode) return node.branchCode;
  if (node.name) return node.name;
  if (node.taAccount && node.clientAccountName) return `${node.taAccount} / ${node.clientAccountName}`;
  if (node.taAccount && !node.clientAccountName) return node.taAccount;
  return t('tkNullDataPlaceholder');
};

// Returns Node Level
export const getNodeLevel = node => {
  if ('firmName' in node) return 0;
  if ('branchName' in node) return 1;
  if ('name' in node && !('taAccounts' in node)) return 2;
  if ('taAccount' in node) return 3;
  if ('name' in node) return 4;
};

// default capabilities
export const defaultCapabilities = {
  canView: false,
  trade: { canTrade: false, tradeLimit: 0, tradeLevel: 'DEFAULT' },
  approver: { canApprove: false, approverLimit: 0, approverLevel: 'L1' },
};

// Returns customized accountDetails array of objects for Ag-Grid
export const customizedAccountsAgTree = (accountDetails = []) => {
  const agTreeRowData = [];
  accountDetails.forEach(firm => {
    const firmUuidv4 = `firm-${uuidv4()}`;
    agTreeRowData.push({
      label: `${firm.firmName} (${firm.firmCode})`,
      capabilities: 'capabilities' in firm ? firm.capabilities : defaultCapabilities,
      assignedDefaultCapabilities: !('capabilities' in firm),
      orgHierarchy: [firmUuidv4],
      rowId: uuidv4(),
      firmName: firm.firmName,
      firmCode: firm.firmCode,
      firmId: firm.id,
    });
    firm &&
      firm.branches &&
      firm.branches.forEach(branch => {
        const branchUuidv4 = `branch-${uuidv4()}`;
        agTreeRowData.push({
          label: `${branch.branchName}`,
          capabilities: 'capabilities' in branch ? branch.capabilities : defaultCapabilities,
          assignedDefaultCapabilities: !('capabilities' in branch),
          orgHierarchy: [firmUuidv4, branchUuidv4],
          rowId: uuidv4(),
          branchName: branch.branchName,
          branchCode: branch.branchCode,
          branchId: branch.id,
          allowBranchLevelTradeApproval: branch.allowBranchLevelTradeApproval,
        });
        branch &&
          branch.accounts &&
          branch.accounts.forEach(account => {
            const accountUuidv4 = `account-${uuidv4()}`;
            agTreeRowData.push({
              label: `${account.name}`,
              orgHierarchy: [firmUuidv4, branchUuidv4, accountUuidv4],
              rowId: uuidv4(),
              name: account.name,
            });
            account &&
              account.taAccounts &&
              account.taAccounts.forEach(taAccount => {
                const taAccountUuidv4 = `taAccount-${uuidv4()}`;
                const accountName =
                  Array.isArray(taAccount.clientAccountName) && taAccount.clientAccountName.length
                    ? taAccount.clientAccountName.sort((a, b) => (a ? (b ? a.localeCompare(b) : -1) : 1)).map(name => name || t('tkCopyPort10'))
                    : [t('tkCopyPort10')];
                agTreeRowData.push({
                  label: `${taAccount.taAccount} / ${accountName.join(', ')}`,
                  orgHierarchy: [firmUuidv4, branchUuidv4, accountUuidv4, taAccountUuidv4],
                  rowId: uuidv4(),
                  taAccount: taAccount.taAccount,
                  clientAccountName: taAccount.clientAccountName,
                  capabilities: 'capabilities' in taAccount ? taAccount.capabilities : defaultCapabilities,
                  assignedDefaultCapabilities: !('capabilities' in taAccount),
                  allowBranchLevelTradeApproval: taAccount.allowBranchLevelTradeApproval,
                });
                taAccount &&
                  taAccount.fundAccounts &&
                  taAccount.fundAccounts.forEach(fundAccount => {
                    const fundAccountUuidv4 = `fundAccount-${uuidv4()}`;
                    agTreeRowData.push({
                      label: `${fundAccount.name}`,
                      rowId: uuidv4(),
                      orgHierarchy: [firmUuidv4, branchUuidv4, accountUuidv4, taAccountUuidv4, fundAccountUuidv4],
                      name: fundAccount.name,
                      id: fundAccount.id,
                      isFundAccountExcluded: fundAccount.isFundAccountExcluded,
                    });
                  });
              });
          });
      });
  });
  return agTreeRowData;
};
