import React from 'react';
import translator from '../../../../../../services/translator';

const { translate: t } = translator;

const CapabilityRenderer = params => {
  const { sidePanelOpen } = params.context;

  const {
    capabilities: { canView = '', trade: { canTrade, tradeLevel = '' } = {}, approver: { canApprove, approverLevel = '' } = {} } = {},
    dynamicIcons,
    newCapabilities: {
      canView: newCanview = '',
      trade: { canTrade: newCanTrade = '' } = {},
      approver: { canApprove: newCanApprove = '' } = {},
    } = params.data.capabilities || {},
  } = params.data;

  const includesLevel = [0, 1, 3].includes(params.node.level);
  const nodeSelected = params.node.selected && !params.data.disabled;
  
  return (
    <span>
      {!sidePanelOpen && includesLevel && (
        <span className='accounts__iconsContainer'>
          {canView && (
            <span className='accounts__iconsContainer-view'>
              <span className='accounts__iconsContainer-viewIcon' />
              {t('tkView')}
            </span>
          )}
          {canTrade && (
            <span className='accounts__iconsContainer-trade'>
              <span className='accounts__iconsContainer-tradeIcon' />
              {tradeLevel === 'DEFAULT' ? 'L0' : tradeLevel} {t('tkTrade')}
            </span>
          )}
          {canApprove && (
            <span className='accounts__iconsContainer-approver'>
              <span className='accounts__iconsContainer-approverIcon' />
              {approverLevel} {t('tkApprover')}
            </span>
          )}
        </span>
      )}
      {sidePanelOpen && includesLevel && dynamicIcons ? (
        <span className='accounts__editSelectedIcons'>
          {newCanview && (
            <span
              className={includesLevel ? (nodeSelected ? 'accounts__editSelectedIcons--viewSelected' : 'accounts__editSelectedIcons--view') : ''}
            />
          )}
          {newCanTrade && (
            <span
              className={includesLevel ? (nodeSelected ? 'accounts__editSelectedIcons--tradeSelected' : 'accounts__editSelectedIcons--trade') : ''}
            />
          )}
          {newCanApprove && (
            <span
              className={
                includesLevel ? (nodeSelected ? 'accounts__editSelectedIcons--approverSelected' : 'accounts__editSelectedIcons--approver') : ''
              }
            />
          )}
        </span>
      ) : null}
    </span>
  );
};

export default CapabilityRenderer;
