import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import useUserMaintenance from '../../../context';
import SelectAll from '../../FieldRenderer/SelectAll';
import FieldRenderer from '../../FieldRenderer';
import translator from '../../../../../../services/translator';
import { featureConfig } from './config';
import { UPDATE_FIELDS, UPDATE_FEATURES_SELECT_ALL } from '../../../actions';
import { YES, NO } from '../../../constants';

const { translate: t } = translator;

const FeaturesGroup = ({ data, selectedKey, title, testId }) => {
  const {
    state: { userDetails, isEditable },
    dispatch,
  } = useUserMaintenance();

  const { isInternal, profile: { entitlements = {} } = {} } = userDetails;

  const disableSelectAll = isInternal || entitlements['mosaic.all'] === YES;

  const handleChange = useCallback(({ value, config }) => {
    dispatch({ type: UPDATE_FIELDS, payload: { newValue: value, path: ['profile', 'entitlements', `mosaic.${config.value}`] } });
  }, []);

  const handleSelectAllChange = useCallback(isSelected => {
    const entitlementChanges = data.reduce((final, { value }) => {
      final[`mosaic.${value}`] = isSelected ? YES : NO;
      return final;
    }, {});
    dispatch({ type: UPDATE_FEATURES_SELECT_ALL, payload: { entitlementChanges } });
  }, []);

  const selectAll = useMemo(() => {
    const totalSelected = data.filter(item => {
      const value = userDetails.profile && userDetails.profile.entitlements && userDetails.profile.entitlements[`mosaic.${item.value}`];
      return value === YES || value === null || value === undefined;
    });
    return totalSelected.length === featureConfig[selectedKey].length;
  }, [userDetails, featureConfig]);

  return (
    <>
      <div data-testid={testId} className='sub-header'>
        {t(title)}
        {isEditable && !disableSelectAll && <SelectAll selected={selectAll} handleSelectAllChange={handleSelectAllChange} />}
      </div>
      {data.map((coreItem, idx, array) => {
        return (
          <FieldRenderer
            key={coreItem.id}
            config={coreItem}
            featuresIcon={entitlements[`mosaic.${coreItem.value}`] === YES}
            value={entitlements[`mosaic.${coreItem.value}`]}
            handleChange={handleChange}
            showNAIfNull
            isEditable={isEditable && !disableSelectAll}
            sectionStart={idx === 0}
            sectionEnd={idx === array.length - 1}
          />
        );
      })}
    </>
  );
};

FeaturesGroup.propTypes = {
  data: PropTypes.array,
  selectedKey: PropTypes.string,
  title: PropTypes.string,
  testId: PropTypes.string,
};

export default FeaturesGroup;
