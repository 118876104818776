import { useState, useCallback, useMemo, useEffect } from 'react';
import CapabilityRenderer from './CapabilityRenderer';

export function useAgTree(gridContainerRef, showAccountSidePanel) {
  const [gridRef, setGridRef] = useState();
  const [columnDefs] = useState([
    {
      headerName: '',
      field: 'capabilityRenderer',
      colId: 'capabilityRenderer',
      cellRenderer: CapabilityRenderer,
      minWidth: 80,
    },
  ]);
  const rowClassRules = useMemo(
    () => ({
      'row-disabled': params => params.data.disabled,
      'different-capabilities-from-parent': params => params.data.orangeNode && params.data.allowBranchLevelTradeApproval,
      'not-configured-for-trade-approval': params =>
        'allowBranchLevelTradeApproval' in params.data ? !params.data.allowBranchLevelTradeApproval : false,
    }),
    []
  );

  const setColumnsWidth = () => {
    setTimeout(() => {
      const width = gridContainerRef.current && gridContainerRef.current.offsetWidth;
      if (gridRef) {
        if (showAccountSidePanel) {
          gridRef.columnApi.setColumnWidth('ag-Grid-AutoColumn', width - 85 - 20);
          gridRef.columnApi.setColumnWidth('capabilityRenderer', 85);
          return
        } else {
          const capabilityRendererWidth = width < 700 ? 250 : width - 700;
          gridRef.columnApi.setColumnWidth(
            'ag-Grid-AutoColumn',
            capabilityRendererWidth === 250 ? width - 250 : width - capabilityRendererWidth
          );
          gridRef.columnApi.setColumnWidth('capabilityRenderer', capabilityRendererWidth);
        }
      }
      gridRef && gridRef.api && gridRef.api.sizeColumnsToFit();
    }, 10);
  };

  const onGridReady = (params) => {
    setGridRef(params);
    params.api.sizeColumnsToFit();
    setColumnsWidth();
  };

  const onGridSizeChanged = () => {
    setColumnsWidth();
  };

  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 300,
      headerName: '',
      field: 'label',
      colId: 'ag-Grid-AutoColumn',
      cellRenderer: 'agGroupCellRenderer',
      sort: 'asc',
      cellRendererParams: {
        suppressDoubleClickExpand: true,
        suppressEnterExpand: true,
        suppressCount: true,
      },
    };
  }, []);

  useEffect(() => {
    setColumnsWidth();
  }, [showAccountSidePanel, gridRef, gridContainerRef]);

  const getDataPath = useCallback(data => data.orgHierarchy, []);

  return {
    columnDefs,
    rowClassRules,
    onGridReady,
    autoGroupColumnDef,
    getDataPath,
    onGridSizeChanged,
    gridRef
  };
}

export const treeConfig = {
  domLayout: 'normal',
  treeData: true,
  rowHeight: 38,
  groupDefaultExpanded: 0,
  rowSelection: 'multiple',
  groupSelectsChildren: false,
  suppressRowClickSelection: true,
  suppressContextMenu: true,
  getRowId: (params) => params.data.rowId,
  suppressScrollOnNewData: false,
};
