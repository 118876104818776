import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Checkbox } from '@gs-ux-uitoolkit-react/checkbox';
import Profile from '../Profile';
import Features from '../Features';
import Accounts from '../Accounts';
import Funds from '../Funds';
import Snackbar from '../../../../../../components/core/Snackbar';
import HelpGuideTray from '../../../../../../components/core/HelpGuideTray';
import RouterPrompt from '../../../../../../components/core/RouterPrompt';
import useUserMaintenance from '../../../context';
import useConsumeHelpGuide from '../../../../../../components/core/HelpGuideTray/useConsumeHelpGuide';
import { Conditional } from '../../../../../../components/core/Conditional';
import { TAB_SWITCHED } from '../../../actions';
import translator from '../../../../../../services/translator';
import { SNACKBAR_AUTO_DISMISS } from '../../../constants';
import { dispatchAnalytics, userDetailsTabChange } from '../../../analytics';
import './index.scss';

const { translate: t } = translator;

export const TABS = {
  PROFILE: 'Profile',
  FEATURES: 'Features',
  ACCOUNTS: 'Accounts',
  FUNDS: 'Funds',
};

export const Tabs = ({ tabId, label, currentTab, switchTab }) => {
  const isTabActive = currentTab === tabId;
  return (
    <button className={cn('tab', { tab__active: isTabActive })} onClick={() => switchTab(tabId)}>
      {label}
    </button>
  );
};

Tabs.propTypes = {
  tabId: PropTypes.string,
  label: PropTypes.string,
  currentTab: PropTypes.string,
  switchTab: PropTypes.func,
};

const TabSwitchBar = () => {
  const tabConfig = useMemo(() => {
    return [
      { id: TABS.PROFILE, label: 'tkProfile' },
      { id: TABS.FEATURES, label: 'tkFeatures' },
      { id: TABS.ACCOUNTS, label: 'tkAccounts' },
      { id: TABS.FUNDS, label: 'tkFunds' },
    ];
  }, []);

  const [currentTab, setCurrentTab] = useState(TABS.PROFILE);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const {
    state: {
      activeTab,
      isPageEdited: isEdited,
      modal: [unsavedChangesModal],
      saveBtnClicked,
      accountEntitlements,
      isEditable,
    },
    dispatch,
    reduxDispatch,
  } = useUserMaintenance();

  const isPageEdited = useMemo(() => {
    return isEdited || (accountEntitlements && !!accountEntitlements.length);
  }, [isEdited, accountEntitlements]);

  const { selectedRowData, handleNextBtn, handlePreviousBtn, toggleHelpGuideActive, helpGuideActive, closeHelpGuide } = useConsumeHelpGuide();

  const switchTab = tabId => {
    setCurrentTab(tabId);
    closeHelpGuide();
    dispatch({ type: TAB_SWITCHED, payload: tabId });
    dispatchAnalytics(reduxDispatch, userDetailsTabChange(tabId));
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const toggleHelpGuid = () => {
    helpGuideActive && setShowSnackBar(true);
    toggleHelpGuideActive(!helpGuideActive);
  };

  useEffect(() => {
    if (currentTab !== activeTab) {
      setCurrentTab(activeTab);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [activeTab, currentTab]);

  useEffect(() => {
    if (saveBtnClicked) {
      closeHelpGuide();
    }
  }, [saveBtnClicked]);

  return (
    <React.Fragment>
      <RouterPrompt when={!!isPageEdited} title={t('tkWarning')} content={unsavedChangesModal.content} />
      <React.Fragment>
        <div className='user-profile-container' data-testid='user-profile-container'>
          {helpGuideActive && showSnackBar && <Snackbar hideTime={SNACKBAR_AUTO_DISMISS} type='info' autoHide={true} msgCopy={t('tkHelpGuide')} />}
          <div className='tabswitchbar' data-testid='tabswitch-container'>
            <div className='tabswitchbar__left'>
              {tabConfig.map(tab => (
                <Tabs key={tab.id} tabId={tab.id} label={t(tab.label)} currentTab={currentTab} switchTab={switchTab} />
              ))}
            </div>
            <div className='tabswitchbar__right'>
              <Checkbox id='toggle-help-guide' checked={helpGuideActive} onChange={toggleHelpGuid}>
                {t('tkTurnOnHelpGuide')}
              </Checkbox>
            </div>
          </div>
        </div>
        <div
          className={cn('details-container', {
            'fullwidth-tab': currentTab === TABS.FUNDS,
            pageInEditMode: isEditable,
          })}
          data-testid='usersearch-details-tabs'>
          <Conditional condition={currentTab === TABS.PROFILE}>
            <div className='tab-content' data-testid='profile-tab-content'>
              <Profile />
            </div>
          </Conditional>
          <Conditional condition={currentTab === TABS.FEATURES}>
            <div className='tab-content' data-testid='features-tab-content'>
              <Features />
            </div>
          </Conditional>
          <Conditional condition={currentTab === TABS.ACCOUNTS}>
            <div className='tab-content' data-testid='accounts-tab-content' style={{ paddingTop: 0 }}>
              <Accounts />
            </div>
          </Conditional>
          <Conditional condition={currentTab === TABS.FUNDS}>
            <div className='tab-content' data-testid='funds-tab-content'>
              <Funds />
            </div>
          </Conditional>
          {!!selectedRowData && helpGuideActive && (
            <HelpGuideTray
              selectedRowData={selectedRowData}
              closeHelpGuide={closeHelpGuide}
              handlePreviousBtn={handlePreviousBtn}
              handleNextBtn={handleNextBtn}
            />
          )}
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};

export default TabSwitchBar;
