import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { DataGrid } from '@gs-ux-uitoolkit-react/datagrid';
import { AllModules } from '@gs-ux-uitoolkit-react/datagrid-modules';
import { Button } from '@gs-ux-uitoolkit-react/button';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { Badge } from '@gs-ux-uitoolkit-react/badge';
import SearchForm from '../../UserDetails/Accounts/SearchForm';
import InnerRenderer from './InnerRenderer';
import FirmOrgMappingModal from '../FirmOrganizationMappingModal';
import FirmOrgUnmappingModal from '../FirmOrganizationUnMappingModal';
import useUserMaintenance from '../../../context';
import useGetAvailableFirmsToMapCount from '../../../hooks/useGetAvailableFirmsToMapCount';
import useGetFirmsToMapList from '../../../hooks/useGetFirmsToMapList';
import useSnackBar from '../../../hooks/useSnackbar';
import { customizedAccountsAgTree } from '../../../utils/accountsTree';
import { searchAndCenter } from '../../../utils/accountsHelper';
import { MAP_UNMAP_FIRMS_LIST, ORG_ACCOUNTS_GRID_BACKUP, UPDATE_FIRMS_AVAILABLE_TO_MAP_COUNT } from '../../../actions';
import translator from '../../../../../../services/translator';

const { translate: t } = translator;

// eslint-disable-next-line max-statements
const Accounts = () => {
  const {
    state: {
      isOrgEditable: isEditable,
      organizationDetails: { accountDetails = [], organizationName },
      firmsAvailableToMapCount,
      mappedFirmsAccounts = [],
      isOrgSaved,
      organizationAccountsGridBackup = [],
    },
    dispatch,
  } = useUserMaintenance();

  const { openSnackbar } = useSnackBar();
  const [gridRef, setGridRef] = useState();
  const { getAvailableFirmsToMapCount, countLoader } = useGetAvailableFirmsToMapCount();
  const { getAvailableFirmsToMap, firmsAvailableToMap, loadingFirmsList } = useGetFirmsToMapList();

  const [searchTerm, setSearchTerm] = useState(null);
  const formRef = useRef();
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const [columnDefs] = useState([]);
  const defaultColDef = useMemo(() => ({ flex: 1 }), []);
  const [gridRowsCount, setGridRowsCount] = useState(0);
  const [firmData, setFirmData] = useState(null);
  const [displayMappingModal, setDisplayMappingModal] = useState(false);
  const [unmappedFirmData, setUnmappedFirmData] = useState(null);

  const onSubmit = inputValue => {
    setSearchTerm(inputValue.trim());
  };

  useEffect(() => {
    if (searchTerm && gridRef?.api) {
      const total = searchAndCenter(gridRef, searchTerm);
      if (!total) {
        formRef.current && formRef.current.setError(true);
      }
    }
  }, [searchTerm]);

  const [, setRefreshCount] = useState(0);

  useEffect(() => {
    if (isEditable) {
      !firmsAvailableToMapCount && getAvailableFirmsToMapCount();
    } else if (!isEditable && !isOrgSaved) {
      gridRef?.api?.setRowData(customizedAccountsAgTree(accountDetails));
    }
    gridRef?.api?.redrawRows();

    return () => {
      if (gridRef?.api) {
        const gridBackup = [];
        gridRef.api.forEachNode(node => {
          gridBackup.push(node.data);
        });
        dispatch({ type: ORG_ACCOUNTS_GRID_BACKUP, payload: gridBackup });
      }
    };
  }, [gridRef, isEditable, accountDetails]);

  useEffect(() => {
    if (mappedFirmsAccounts.length && gridRef?.api) {
      const copyMappedFirmsAccounts = [...mappedFirmsAccounts];
      const mappedFirmAccountIds = mappedFirmsAccounts.map(firm => firm.id);
      const newRowData = [];
      gridRef.api.forEachNode(node => {
        if ('firmId' in node.data && mappedFirmAccountIds.includes(node.data.firmId)) {
          const idx = copyMappedFirmsAccounts.findIndex(firm => firm.id === node.data.firmId);
          if (idx > -1) {
            copyMappedFirmsAccounts.splice(idx, 1);
          }
        }
        newRowData.push(node.data);
      });
      gridRef.api.setRowData([...newRowData, ...customizedAccountsAgTree(copyMappedFirmsAccounts)]);
    }
  }, [gridRef, mappedFirmsAccounts]);

  useEffect(() => {
    if (unmappedFirmData) {
      const newRowData = [];
      let firmUniqueId;
      gridRef.api.forEachNode(node => {
        if (!firmUniqueId && node.data.firmId === unmappedFirmData.firmId) {
          [firmUniqueId] = node.data.orgHierarchy;
        }
        if (!node.data.orgHierarchy.includes(firmUniqueId)) {
          newRowData.push(node.data);
        }
      });
      gridRef.api.setRowData(newRowData);
      dispatch({ type: MAP_UNMAP_FIRMS_LIST, payload: [{ ...unmappedFirmData }] });
      dispatch({ type: UPDATE_FIRMS_AVAILABLE_TO_MAP_COUNT, payload: firmsAvailableToMapCount + 1 });
      openSnackbar({
        type: 'success',
        message: t('tkUnMapFirmToOrganizationSucess', unmappedFirmData.firmName, unmappedFirmData.firmCode, organizationName),
      });
    }
  }, [gridRef, unmappedFirmData]);

  const onGridReady = useCallback(
    params => {
      setGridRef(params);
      params.api.setRowData(organizationAccountsGridBackup.length ? organizationAccountsGridBackup : customizedAccountsAgTree(accountDetails));
      params.api.sizeColumnsToFit();
      // rerendering the component to get updated count
      setRefreshCount(Math.random() * 1000);
    },
    [accountDetails, organizationAccountsGridBackup]
  );

  const onGridSizeChanged = useCallback(params => {
    params.api.sizeColumnsToFit();
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 300,
      headerName: '',
      field: 'label',
      cellRenderer: 'agGroupCellRenderer',
      sort: 'asc',
      cellRendererParams: {
        innerRenderer: InnerRenderer,
        suppressDoubleClickExpand: true,
        suppressEnterExpand: true,
        suppressCount: true,
      },
    };
  }, []);

  const getDataPath = useMemo(() => data => data.orgHierarchy, []);

  const onExpandAll = () => {
    gridRef.api.expandAll();
    gridRef.api.ensureIndexVisible(0, 'top');
  };

  const onCollapseAll = () => {
    gridRef.api.collapseAll();
    gridRef.api.ensureIndexVisible(0, 'top');
  };

  const closeUnmappingModal = () => {
    setFirmData(null);
  };

  const closeMappingModal = () => {
    setDisplayMappingModal(false);
  };

  const onRowClicked = params => {
    if (params.event.target.className.includes('unmap-container')) {
      setFirmData(params.data);
    }
  };

  const handleMapAFirm = () => {
    setDisplayMappingModal(true);
    getAvailableFirmsToMap();
  };

  const overlayNoRowsTemplate = () => (
    <div className='accounts__noContent' style={{ position: 'absolute' }}>
      {t('tkNoAccountData')}
    </div>
  );

  const onRowDataUpdated = params => {
    const rowCount = params.api.getDisplayedRowCount();
    if (rowCount !== gridRowsCount) {
      setGridRowsCount(rowCount);
    }
  };

  return (
    <div className='accounts account-tree ag-tree'>
      <div className='accounts__controlpanel'>
        <div className='accounts__mainControls'>
          {!!gridRowsCount && (
            <div className='accounts__searchContainer'>
              <SearchForm ref={formRef} onSubmit={onSubmit} />
              <span className='accounts__searchIcon' />
            </div>
          )}
          {isEditable && (
            <div className='accounts__mapContainer'>
              <Button actionType='primary' emphasis='subtle' size='sm' onClick={handleMapAFirm}>
                <Icon name='add' type='filled' /> {t('tkMapANewFirm')}
                {firmsAvailableToMapCount || !countLoader ? (
                  <Badge status='error' shape='rounded' size='sm' data-testid='firms-badge-count'>
                    {firmsAvailableToMapCount}
                  </Badge>
                ) : (
                  <Icon name='loading' type='filled' spin />
                )}
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className='accounts__entitlementSection'>
        <div className='accounts__entitlementHeader'>{t('tkAccounts')}</div>
        <div className='accounts__entitlementSubHeader'>{t('tkOrganizationAccountsSubtitle')}</div>
        {!!gridRowsCount && (
          <div className='accounts__buttonsContainer'>
            <button className='accounts__expandBtn' onClick={onExpandAll}>
              {t('tkExpandAll')}
            </button>
            <button className='accounts__collapseBtn' onClick={onCollapseAll}>
              {t('tkCollapseAll')}
            </button>
          </div>
        )}
      </div>
      <div className='accounts__treeSection'>
        <div style={gridStyle} className='ag-theme-balham'>
          <DataGrid
            modules={AllModules}
            suppressBrowserResizeObserver={true}
            domLayout='normal'
            ref={gridRef}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            treeData={true}
            rowHeight={38}
            onGridSizeChanged={onGridSizeChanged}
            onFirstDataRendered={onGridSizeChanged}
            onGridReady={onGridReady}
            groupDefaultExpanded={0}
            groupSelectsChildren={false}
            suppressRowClickSelection={true}
            suppressContextMenu={true}
            getDataPath={getDataPath}
            context={{ searchTerm }}
            id='OrganizationDetails'
            getRowId={params => params.data.rowId}
            suppressScrollOnNewData={false}
            onRowClicked={onRowClicked}
            noRowsOverlayComponent={overlayNoRowsTemplate}
            onRowDataUpdated={onRowDataUpdated}
          />
        </div>
      </div>
      {displayMappingModal && <FirmOrgMappingModal firmsData={firmsAvailableToMap} closeModal={closeMappingModal} loading={loadingFirmsList} />}
      {firmData && <FirmOrgUnmappingModal closeModal={closeUnmappingModal} firmData={firmData} updateUnmappedFirmData={setUnmappedFirmData} />}
    </div>
  );
};

export default Accounts;
