import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import UserMaintenanceProvider from '../../context/provider/UserMaintenanceProvider';
import UserDetails from '../UserDetails';
import OrganizationDetails from '../OrganizationDetails';
import useUserMaintenance from '../../context';
import { ThreeSquaresLoader } from '../../../../../components/core/Loaders';
import { ORGANIZATIONS, USERS } from '../../constants';

const Wrapper = ({ pageKey, userDetails, organizationDetails }) => {
  const {
    state: { showLoader },
  } = useUserMaintenance();

  const detailsPageClass = classNames(
    'usersearch',
    'usersearch-details',
    { organizationdetails: pageKey === ORGANIZATIONS },
    { 'has-opacity': showLoader }
  );

  return (
    <section data-testid='user-details-page' className={detailsPageClass}>
      {showLoader && <ThreeSquaresLoader />}
      {pageKey === USERS && <UserDetails userDetails={userDetails} />}
      {pageKey === ORGANIZATIONS && <OrganizationDetails organizationDetails={organizationDetails} />}
    </section>
  );
};

Wrapper.propTypes = {
  userDetails: PropTypes.object,
  organizationDetails: PropTypes.object,
  pageKey: PropTypes.string,
};

const UserMaintenanceWithProvider = ({ storeValues, pageKey, userDetails, organizationDetails, reduxDispatch }) => {
  return (
    <UserMaintenanceProvider storeValues={storeValues} reduxDispatch={reduxDispatch}>
      <Wrapper pageKey={pageKey} organizationDetails={organizationDetails} userDetails={userDetails} />
    </UserMaintenanceProvider>
  );
};

UserMaintenanceWithProvider.propTypes = {
  userDetails: PropTypes.object,
  reduxDispatch: PropTypes.func,
  storeValues: PropTypes.object,
  organizationDetails: PropTypes.object,
  pageKey: PropTypes.string,
};

export default UserMaintenanceWithProvider;
